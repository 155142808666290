import { inject, Injectable } from '@angular/core';
import {
  getCommunityNotificationCount,
  getServicesNotificationCount,
} from '@flink-legacy/core/states/message-notifications-state/message-notifications.selectors';
import { Store } from '@ngrx/store';
import { filter, first, map, Observable, of } from 'rxjs';
import { marker as _ } from '@bling-fe/shared/utils';
import { ModalService } from '@bling-fe/shared/modals/base-modal';
import { baseIcon } from '@bling-fe/shared/icons/index';
import { Tenant } from '@flink-legacy/core/declarations/tenant.interface';
import { getCurrentTenant } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { NewModalComponent } from '@bling-fe/ihz-member/shared/new-modal';

export type MenuItemDetail = {
  label: string;
  url: string | null;
  onClick?: () => void;
  icon: baseIcon['name'];
  notificationCount$: Observable<number>;
};

export type MenuItem =
  | 'NEW'
  | 'HOME'
  | 'CALENDAR'
  | 'COMMUNITY'
  | 'SERVICES'
  | 'ROOM_BOOKING'
  | 'HELP'
  | 'MY_PROFILE';

@Injectable({
  providedIn: 'root',
})
export class MainMenuService {
  private modalService = inject(ModalService);
  tenant$ = this.tenantStore.select(getCurrentTenant).pipe(
    filter(t => t !== 'loading'),
    map(t => t as Tenant),
    first()
  );

  STATIC_ITEMS = {
    NEW: {
      label: _('IHZ_MEMBER.UI_LAYOUT.MENU.NEW'),
      url: null,
      onClick: () => {
        this.modalService.presentAndGetResult(NewModalComponent, {});
      },
      icon: 'plus-dark-circle',
      notificationCount$: of(0),
    },
    HOME: {
      label: _('IHZ_MEMBER.UI_LAYOUT.MENU.HOME'),
      url: '/home',
      icon: 'news',
      notificationCount$: of(0),
    },
    CALENDAR: {
      label: _('IHZ_MEMBER.UI_LAYOUT.MENU.CALENDAR'),
      url: '/calendar',
      icon: 'calendar',
      notificationCount$: of(0),
    },
    COMMUNITY: {
      label: _('IHZ_MEMBER.UI_LAYOUT.MENU.COMMUNITY'),
      url: '/member',
      icon: 'users',
      notificationCount$: inject(Store).select(getCommunityNotificationCount),
    },
    SERVICES: {
      label: _('IHZ_MEMBER.UI_LAYOUT.MENU.SERVICES'),
      url: '/services',
      icon: 'reception-bell',
      notificationCount$: inject(Store).select(getServicesNotificationCount),
    },
    ROOM_BOOKING: {
      label: _('IHZ_MEMBER.UI_LAYOUT.MENU.ROOM_BOOKING'),
      url: '/room-booking',
      icon: 'home',
      notificationCount$: of(0),
    },
    MY_PROFILE: {
      label: _('IHZ_MEMBER.UI_LAYOUT.MENU.MY_PROFILE'),
      url: '/my-profile',
      icon: 'user',
      notificationCount$: of(0),
    },
  };

  constructor(private tenantStore: Store<Tenant>) {}

  public items(): Observable<Record<MenuItem, MenuItemDetail>> {
    return this.tenant$.pipe(
      map(tenant => {
        return {
          HELP: {
            label: _('IHZ_MEMBER.UI_LAYOUT.MENU.HELP'),
            url: tenant.tenant_setting.help_page_url || '/help',
            icon: 'help-chat',
            notificationCount$: of(0),
          },
          ...this.STATIC_ITEMS,
        } as Record<MenuItem, MenuItemDetail>;
      })
    );
  }
}
